.formContainer {
  margin: 20px 0;
  width: 60%;
}

.button {
  max-width: 15%;
  min-width: 130px;
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.firstGrid {
  margin-bottom: 5px;
}

.label {
  margin-bottom: 15px;
}

.address {
  display: flex;
}

.locationIcon {
  margin-right: 5px;
}
