@import 'src/app/styles/variables/_colors.module.scss';

.root {
  display: inline-block;
  .container {
    display: flex;
    align-items: center;
    padding: 2px 16px;

    .icon {
      svg {
        width: 10px;
        height: 10px;
      }
      margin-right: 5px;
    }
  }
}