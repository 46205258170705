.root {
  display: flex;

  .selectorButtonContainer {
    display: flex;
    justify-content: flex-start;
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 10px;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    padding-left: 15px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    margin-right: 15px;
    color: #90ffff;
    svg {
      margin-right: 10px;
      height: 16px;
      width: 16px;
      path {
        fill: #90ffff;
      }
    }
    .findtypos {
      margin-right: 5px;
    }
    &.inactive {
      opacity: 0.7;
      &:hover {
        opacity: 0.8;
      }
      color: #e4e4e4;
      svg {
        path {
          fill: #e4e4e4;
        }
      }
    }
  }
  .inputSearch input {
    width: 315px;
    font-weight: 500;
    height: 33px;
    padding: 0;
    color: #fff;
  }
  .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    margin: 12px 15px;
    background: #333;
  }
  .noResults {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 150px;
    .noResultsText {
      text-align: center;
      font-weight: 400;
      margin-left: 10px;
      opacity: 0.8;
    }
    .noResultsIcon {
      opacity: 0.8;
    }
  }
  .resultadosBlock,
  .recentSearch {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 230px;
    max-height: 200px;
    padding-top: 10px;

    .listItem {
      display: flex;
      padding-left: 30px;
      justify-content: flex-start;
      padding-right: 0;
      height: 40px;
      align-items: center;
      &:last-child {
        margin-bottom: 10px;
      }
      .listItemTextId {
        font-size: 14px;
        font-weight: 600;
        margin-right: 8px;
        text-overflow: ellipsis;
        width: 100px;
        overflow: hidden;
        white-space: nowrap;
      }
      .listItemTextDetail {
        font-size: 14px;
        font-weight: 400;
        text-overflow: ellipsis;
        width: 192px;
        overflow: hidden;
        white-space: nowrap;
      }
      .listItemIcon {
        opacity: 0.5;
        margin-right: 10px;
        height: 16px;
        width: 16px;
      }
      .clearRecentButton {
        position: absolute;
        right: 15px;
      }
    }
  }
  .recentSearch {
    .listItemTextDetail {
      width: 140px !important;
    }
  }
  .recentSearchesTitle {
    font-size: 14px;
    font-weight: 200;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  .searchResults {
    margin-bottom: 10px;
    p {
      font-size: 14px;
      font-weight: 200;
    }
    padding-left: 20px;
    .number {
      font-weight: 600;
      margin-right: 5px;
    }
  }
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
