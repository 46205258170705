.root {
  position: relative;
  display: flex;
  flex-grow: 1;
  background-color: #313131;

  .hierachySelectorWrapper {
    z-index: 99;
    position: absolute;
    top: 30px;
    left: 45px;
  }

  .hierachyResetButtonWrapper {
    z-index: 99;
    position: absolute;
    top: 30px;
    left: 420px;
    .resetHierarchyButton {
      height: 40px;
      padding: 0 20px;
      background-color: #434343;
    }
  }

  .clusterViewButtonWrapper {
    z-index: 99;
    position: absolute;
    top: 30px;
    left: 600px;
    .clusterViewButton {
      height: 40px;
      padding: 0 20px;
      border: none;
      background-color: #434343;
    }
  }

  .statisticsOperationalPanelWrapper {
    z-index: 99;
    position: absolute;
    bottom: 30px;
    left: 45px;
    width: 457px;
  }

  .detailPanelWrapper {
    z-index: 99;
    position: absolute;
    top: 0;
    right: 0;
  }

  .searchWrapper {
    z-index: 99;
    position: absolute;
    top: 30px;
    right: 45px;
  }

  /* Controles de mapa propietario */
  .controlGeolocateStyle {
    bottom: 200px;
    right: 200px;
    padding: 10px;
    filter: invert(0.85);
  }
  .controlNavStyle {
    bottom: 260px;
    right: 200px;
    padding: 10px;
    filter: invert(0.85);
  }
  .controlScaleControlStyle {
    bottom: 380px;
    right: 200px;
    padding: 10px;
    filter: invert(0.85);
  }
}
