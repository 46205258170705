@import 'src/app/styles/variables/_colors.module.scss';

.root {
  display: inline-block;
  .container {
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 2px;
    &.withLabel {
      &.alert {
        padding: 2px 10px 2px 5px;
      }
      flex-grow: 1;
      padding: 2px 8px 2px 3px;
      .icon {
        margin-right: 5px;
      }
    }
    &.alert {
      padding: 2px 3px 2px 2px;
      background-color: $alerta;
      .icon {
        fill: rgba(255, 255, 255, 0.9);
        // animation: flickerAnimation 2s infinite;
      }
    }
    &.warning {
      background-color: $atencion;
      .icon {
        fill: rgba(0, 0, 0, 0.5);
      }
      .label {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 600;
      }
    }
    &.ok {
      background-color: $correcto;
      .icon {
        fill: rgba(0, 0, 0, 0.5);
      }
      .label {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 600;
      }
    }
    .icon {
      width: 16px;
      height: 16px;
    }
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
