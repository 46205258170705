.modal {
  width: 55%;
  height: 700px;
  margin: auto;
}

.dialogcontent {
  min-height: 200;
  width: auto;
  padding: 15px 20px;
  border-bottom: none;
}

.container {
  display: flex;
  margin-bottom: 20px;
}

.containerButton {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0px;
  padding-top: 10px;
}

.button {
  width: 130px;
}

.text {
  color: #bfbfbf;
}

.cardSending {
  height: 400px;
  padding: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardOkResponse {
  height: 400px;
  padding: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #bfbfbf;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #333 inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #ffffff !important;
}

.input {
  width: 100%;
  padding-left: 20px;
  background-color: #333;
  border-color: #333;
}

.cardInput {
  margin-bottom: 20px;
  background-color: #242424;
  width: 430px;
}

.cardInputTextArea {
  margin-bottom: 20px;
  background-color: #242424;
}

.formLabel {
  margin-bottom: 5px;
  margin-left: 1px;
  color: #ffffff9c;
}

.card {
  padding: 20px;
}

.infoIcon {
  margin-right: 12px;
}

.hidden {
  display: none;
}
