@import 'src/app/styles/variables/_colors.module.scss';

.alert {
  background-color: $alertaRow !important;
  &:hover {
    filter: brightness(1.4);
  }
}

.warning {
  background-color: $atencionRow !important;
  &:hover {
    filter: brightness(1.4);
  }
}
