.backRowAlert {
  background-color: rgba(232, 12, 44, 0.15) !important;
  &:hover {
    filter: brightness(1.4);
  }
}
.backRowWarning {
  background-color: rgba(255, 171, 40, 0.15) !important;
  &:hover {
    filter: brightness(1.1);
  }
}

.statusDate {
  font-size: 11px !important;
  opacity: 0.6;
}

.selectField {
	height: auto;
  width: 120px;
}
.typeIcon {
  margin-right: 10px;
  color: #fff;
  height: 18px;
  width: 18px;
}