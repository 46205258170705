.panelContainer {
  @extend .unselectable;
  display: flex;
  flex-direction: column;
  padding: 15px 20px 10px 20px;
  min-height: 275px;
  justify-content: flex-start;
  .noResults {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 220px;
    .noResultsText {
      text-align: center;
      font-weight: 400;
      margin-left: 10px;
      opacity: 0.8;
    }
    .noResultsIcon {
      opacity: 0.8;
    }
  }
  .detail {
    display: flex;
    flex-direction: column;
    height: 104px;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
  .dataRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
  }
  .mainIcon {
    height: 27px;
    width: 27px;
    margin-right: 10px;
    margin-top: 5px;
  }
  .fontTitle {
    font-size: 16px;
    font-weight: 600;
    opacity: 0.8;
    align-self: flex-end;
    margin-bottom: 2px;
  }
  .fontTitleNumber {
    line-height: 40px;
    font-size: 40px;
    margin-right: 10px;
    font-weight: 600;
    align-self: flex-end;
  }
  .fontSubTitle {
    font-size: 14px;
    font-weight: 300;
    opacity: 0.8;
  }
  .typeIcon {
    margin-right: 10px;
    color: #fff;
    height: 15px;
    width: 15px;
  }
  .alertTypes {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 5px 0;
    min-height: 25px;
  }
  .alertTypesRow {
    display: flex;
    align-items: center;
  }
  .operationStatusTypo {
    margin: 0 10px 0 6px;
    text-transform: uppercase;
    font-size: 12px;
  }
  .totalTypesTitle {
    width: 200px;
    padding-left: 55px;
    margin-right: 40px;
  }

  .typeText {
    min-width: 80px;
    margin-left: 0;
    margin-right: 15px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    opacity: 0.7;
    &.noFilter {
      min-width: 110px;
    }
  }
  .typeNumber {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    .typeValue {
      min-width: 30px;
    }
    .typeUnit {
      color: #fff;
      font-size: 14px;
      font-weight: 300;
      margin-left: 5px;
      margin-bottom: -1px;
    }
  }

  .currentFilter {
    opacity: 0.5;
  }
  .detailRow {
    display: flex;
    height: 18px;
    margin-bottom: 7px;
    margin-top: 8px;
  }
  .detailRowFirst {
    display: flex;
    width: 190px;
    align-items: center;
    margin-right: 40px;
  }

  &.devices {
    .detail {
      height: 138px;
    }
  }
}
.button {
  &.viewIconTop,
  &.viewIconBottom {
    cursor: pointer;
    color: #e8dedd;
    font-size: 20px;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  &.viewIconTop {
    margin-right: 10px;
  }
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
