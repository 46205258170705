
  .dz-calendar {
    width: 296px;
    height: 354px;
  }
  .dz-calendar .dz-calendar-content {
    padding: 0 8px 8px;
  }

  .dz-calendar-header {
    padding: 4px 8px 0 8px;
  }
  .dz-calendar-header .header__content {
    display: flex;
    justify-content: space-between;
    margin: 14px 0;
  }
  .dz-calendar-header .header__content__spacer {
    flex: 1 1 auto;
  }
  .dz-calendar-header .header__content__previous-button,
  .dz-calendar-header .header__content__next-button {
    width: 40px;
    height: 40px;
  }

  .dz-calendar__table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  .dz-calendar__table__header {
    opacity: 0.8;
  }
  .dz-calendar__table__header th {
    padding: 0 0 8px;
    font-size: 11px;
    font-weight: 400;
  }
  .dz-calendar__table__header__divider {
    position: relative;
    height: 1px;
  }
  .dz-calendar__table__header__divider:after {
    content: '';
    position: absolute;
    top: 0;
    left: -8px;
    right: -8px;
    height: 1px;
    background: rgba(0, 0, 0, 0.12);
  }
  .dz-calendar__table__body {
    font-size: 13px;
  }
  .dz-calendar__table__body__cell {
    position: relative;
    outline: 0;
    cursor: pointer;
    width: 14.2757%;
    padding-top: 7.14286%;
    padding-bottom: 7.14286%;
  }
  .dz-calendar__table__body__cell--disabled .dz-calendar__table__body__cell__content {
    opacity: 0.6;
  }
  .dz-calendar__table__body__cell--disabled {
    cursor: default;
  }
  .dz-calendar__table__body__cell--begin-range:not(.dz-calendar__table__body__cell--end-range) {
    border-radius: 100% 0 0 100%;
    background-color: #93f1ff48;
  }
  .dz-calendar__table__body__cell--end-range:not(.dz-calendar__table__body__cell--begin-range) {
    border-radius: 0 100% 100% 0;
    background-color: #93f1ff48;
  }
  .dz-calendar__table__body__cell--begin-range .dz-calendar__table__body__cell__content,
  .dz-calendar__table__body__cell--end-range:not(.dz-calendar__table__body__cell--maybe-end) .dz-calendar__table__body__cell__content {
    background-color: #93f1ff;
    color: #616161 !important;
    font-weight: 600;
  }
  .dz-calendar__table__body__cell--semi-selected {
    background-color: #93f1ff48;
  }
  .dz-calendar__table__body__cell--today .dz-calendar__table__body__cell__content {
    border: solid 1px #93f1ff;
    color: #93f1ff;
  }
  .dz-calendar__table__body__cell--maybe-end .dz-calendar__table__body__cell__content {
    border: #93f1ff 1px solid;
  }
  .dz-calendar__table__body__cell:hover:not(.dz-calendar__table__body__cell--begin-range):not(.dz-calendar__table__body__cell--end-range):not(.dz-calendar__table__body__cell--disabled)
    .dz-calendar__table__body__cell__content,
  .dz-calendar__table__body__cell--focus:not(.dz-calendar__table__body__cell--begin-range):not(.dz-calendar__table__body__cell--end-range):not(.dz-calendar__table__body__cell--disabled)
    .dz-calendar__table__body__cell__content,
  .dz-calendar__table__body__cell--active .dz-calendar__table__body__cell__content {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .dz-calendar__table__body__cell__content {
    position: absolute;
    top: 5%;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    line-height: 1;
    border-radius: 999px;
  }
