@import './app/styles/variables/_colors.module';

.actionButton {
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}
.editField {
  padding-left: 10px;
  input {
    height: 8px;
    font-size: 12.8px;
    color: $primary;
  }
}
