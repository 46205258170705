@import 'src/app/styles/variables/_colors.module.scss';

.sectionWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.cardSkeleton {
  padding: 22px;
  flex: 1 1 0px;
  min-width: 330px;
  transition: filter ease 0.5s !important;
}

.card {
  padding: 5px 5px 15px 5px;
  flex: 1 1 0px;
  min-width: 330px;
  transition: filter ease 0.5s !important;
  
}



.OperationBadgeLabel_ok {
  color: $correcto;
  font-weight: 600;
}

.OperationBadgeLabel_warning {
  color: $atencion;
  font-weight: 600;
}

.OperationBadgeLabel_alert {
  color: $alerta;
  font-weight: 600;
}

