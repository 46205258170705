
.backRowAlert {
  background-color: rgba(232, 12, 44, 0.15) !important;
  &:hover {
    filter: brightness(1.4);
  }
}
.statusDate {
  font-size: 11px !important;
  opacity: 0.6;
}