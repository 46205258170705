@import 'variables/_fontFamilies.module.scss';
@import 'variables/_colors.module.scss';

html,
body {
  margin: 0;
  padding: 0;
  * {
    box-sizing: border-box;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $system-font, sans-serif;
 // background-image: url('../../app/assets/img/keycloak-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  letter-spacing:0.03em;
}

#root {
  height: 100%;
}

.react-app-root {
  height: 100%;
}

*.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
