
.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px 40px;
  overflow-y: scroll;
}

.blockContainer {
  padding: 10px 30px 20px 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  background-color: #292929 !important;
}
