@import '../../../../../../../styles/variables/colors.module';

.container {
  display: flex;
}

.tableRoot {
  overflow: auto;
}

.tableHeader {
  text-align: left;
}

.tableCell {
  padding: 0;
  text-align: center;
}

.layoutContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0 10px;
}

.backRowAlert {
  background-color: rgba(232, 12, 44, 0.15) !important;
  &:hover {
    filter: brightness(1.4);
  }
}
.backRowWarning {
  background-color: rgba(255, 171, 40, 0.15) !important;
  &:hover {
    filter: brightness(1.1);
  }
}
