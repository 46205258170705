@import 'src/app/styles/variables/_colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 10px;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
}
.icon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 8px;
}

.serial {
  color: $primary;
  text-transform: uppercase;
}
