.closeIcon {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.detailWrapper {
  padding: 25px 30px 20px 30px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .addressBlock {
    padding: 0 0 0 18px;
  }
  .detailHeader {
    margin-bottom: 20px;
    .iconType {
      height: 24px;
      width: 24px;
      margin-right: 15px;
    }
    .titleBlock {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;
      .title {
        font-size: 26px;
        font-weight: 700;
      }
    }
    .subtitleId {
      font-size: 16px;
      font-weight: 600;
    }
    .subtitleName {
      font-size: 16px;
      font-weight: 500;
      opacity: 0.8;
      margin-bottom: 10px;
    }
    .subtitleAddress {
      font-size: 14px;
      font-weight: 600;
      opacity: 0.8;
    }
  }
}
.operationStatusWrapper {
  width: 100%;
  margin: 15px 0 10px 0;
}
.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  background: #333333 !important;
  .searchIcon {
    margin-right: 10px;
    opacity: 0.6;
  }
  .closeIcon {
    cursor: pointer;
  }
  .input {
    flex-grow: 1;
    font-weight: 500;
  }
}

.interiorCard {
  display: flex;
  padding: 5px;
  flex-direction: column;

  overflow-y: scroll !important;
  flex-grow: 1;
  .deviceRow {
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
  }
  .deviceTabLabel {
    &.selected {
      font-weight: 600;
    }
  }
  .noDataPanel {
    padding: 20px;
    display: flex;
    background-color: #373737;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 15px;
    .noData {
      opacity: 0.5;
      align-self: center;
    }
  }
  .dataPanel {
    margin: 15px !important;
  }
}
.dataPanel {
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #373737;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 4px;

  .measureRow {
    display: flex;
    margin-bottom: 5px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .label {
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 210px;
    }
    .value {
      font-size: 13px;
      font-weight: 400;
      white-space: nowrap;
    }
    .separator {
      align-self: self-end;
      border-bottom: 2px dotted #cccccc5e;
      display: flex;
      flex: 1;
      margin: 0 10px 5px 10px;
    }
  }
  .deviceCod {
    font-size: 18px;
    font-weight: 600;
    margin-right: 10px;
    cursor: default;
  }
  .deviceCodLabel {
    font-size: 14px;
    font-weight: 400;
  }
  .devicesIcon {
    width: 16px;
    margin-right: 10px;
  }
  .timerIcon {
    opacity: 0.5;
    width: 16px;
    margin-right: 5px;
  }
  .title {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
  }
  .dateLabel {
    font-size: 12px;
    font-weight: 300;
    margin-left: 26px;
    text-transform: uppercase;
  }
}

.noResults {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 150px;
  .noResultsText {
    text-align: center;
    font-weight: 400;
    margin-left: 10px;
    opacity: 0.8;
  }
  .noResultsIcon {
    opacity: 0.8;
  }
}
