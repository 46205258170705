.errorContainer {
  display: flex;
  align-items: center;
}

.list-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.item {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 3px 0;
  min-height: 35px;
}

.icon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}