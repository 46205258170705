.tooltipHierarchy {
  transition: all opacity ease-in 0.2s;
  position: absolute;
  width: 240px;
  opacity: 0;
  z-index: -999;
  background: #424242e6;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  .title {
    font-size: 15px;
    font-weight: 600;
    opacity: 0.8;
    text-align: center;
  }
  .detailContent {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 5px;
    padding-top: 8px;
    .tooltipHierarchyRow {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 130px;
        .icon {
          margin-right: 10px;
          height: 18px;
          display: inline-block;
        }
        .value {
          align-self: flex-end;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
}
