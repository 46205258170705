.tabs {
  margin-bottom: 20px;
  margin-left: 200px;
  margin-right: 100px;
  flex: 0 1 5vh;
}
.tabContent {
  display: flex;
  height: 100%;
}
.tab {
  min-width: 160px;
}
