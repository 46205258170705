
.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px 40px;
  overflow-y: scroll;
}

.blockContainer {
  padding: 10px 30px 0 30px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  background-color: #292929 !important;
}
.skeletonCard {
  width: 375px;
  height: 270px;
  margin: 20px;
  border-radius: 5px;
}
.card {
  padding: 22px;
  margin: 0 40px 40px 0;
  min-width: 330px;
  max-width: 500px;
  cursor: pointer;
  transition: filter ease 0.5s !important;
  &:hover {
    filter: brightness(1.2);
  }
}

.backRowAlert {
  background-color: rgba(232, 12, 44, 0.15) !important;
  &:hover {
    filter: brightness(1.4);
  }
}
.deviceIcon {
  width: 35px;
  height: 35px;
}
.statusDate {
  font-size: 11px !important;
  opacity: 0.6;
}