//@import 'scss/variables/_colors.scss';
//@import 'scss/variables/_fontSizes.scss';

.info {
  background-color: blue; //$notification-info;
}

.error {
  background-color: red; //$notification-error;
}

.success {
  background-color: green; //$notification-success;
}

.snackbarContent {
  flex-wrap: nowrap;
  padding: 6px 15px;
}

.action {
  padding-left: 10px;
}

.content {
  align-items: center;
  display: flex;
}

.alertTypeIcon {
  font-size: 20px;
  margin-right: 10px;
  opacity: 0.9;
}
