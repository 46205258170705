.container {
  background-color: #2e2e2e;
  border-radius: 6px;
  padding: 10px 20px;
  flex: 1;

  .loading {
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
  }
}
