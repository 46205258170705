.container {
  display: flex;
}

.text {
  color: #bfbfbf;
}
.infoIcon {
  margin-right: 20px;
}

