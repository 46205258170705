.actionMenu {
  left: 20px;
}
.actionButton {
  width: 26px;
  height: 26px;
  display: flex;
  transition: background-color ease 0.5s;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: rgb(117, 117, 117);
  }
}
.backRowAlert {
  background-color: rgba(232, 12, 44, 0.15) !important;
  &:hover {
    filter: brightness(1.4);
  }
}

.test {
  .MuiInput-root {
    background-color: red !important;
  }
}
