.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 50px 0 0;
  .groupTitle {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.7;
    color: #fff;
    margin-bottom: 6px;
  }
  .container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    .button {
      display: flex;
      flex-direction: column;
      align-items: center;
      .chip {
        height: 18px;
        font-size: 10px;
        svg {
          margin-left: -1px;
        }
        &.active {
          opacity: 1;
        }
        &.inactive {
          opacity: 0.5;
        }
      }
    }
    .valueLegend {
      margin-top: 2px;
      font-size: 11px;
    }
  }
}
