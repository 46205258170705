@import 'src/app/styles/variables/_colors.module.scss';

.root {
  display: flex;
  width: 200px;
  background-color: #5d4e4a;
  border-radius: 3px;
  overflow: hidden;
}

.alert,
.warning,
.correct {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  transition: width cubic-bezier(0, 0, 0.2, 1) 1.5s;
  padding-right: 5px;
  padding-bottom: 1px;
}

.alert {
  background-color: $alerta;
  color: #fff;
}

.warning {
  background-color: $atencion;
  color: rgba(0, 0, 0, 0.6);
}

.correct {
  background-color: $correcto;
  color: rgba(0, 0, 0, 0.6);
}

.onFilter {
  opacity: 0.5;
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
