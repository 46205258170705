.dialogcontent {
  width: auto;
  padding: 15px 20px;
  border-bottom: none;
}

.infoContainer {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.containerButton {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0px;
  padding-top: 10px;
}

.button {
  width: 130px;
}

.text {
  color: #bfbfbf;
}
.card {
  padding: 20px;
  min-height: 400px;
}

.infoIcon {
  margin-right: 12px;
}
.selectField {
  & > div > div {
    padding: 10px !important;
    font-size: 14px;
    min-width: 200px;
  }
  margin-bottom: 30px;
}

.dateField input {
  padding: 10px 10px 12px 10px;
  font-size: 14px;
  color: white !important;
}

.fieldHint {
  margin-top: 4px;
  font-size: 12px;
  opacity: 0.4;
}

.filterSection {
  overflow-y: hidden;
  overflow-x: scroll;
  min-height: 250px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  padding: 20px 0;

  .varGroup {
    display: flex;
    flex-direction: column;
    background: #373737b5;
    padding: 10px 15px 15px 15px;
    border-radius: 6px;
    .heading {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      padding-bottom: 5px;
      .title {
        white-space: nowrap;
        font-size: 15px;
        opacity: 0.7;
      }
      .allNone {
        color: #93f1ff;
        font-size: 14px;
        opacity: 0.8;
        cursor: pointer;
        min-width: 55px;
        user-select: none;
        text-align: end;
        &:hover {
          opacity: 1;
        }
      }
    }

    .content {
      padding-top: 10px;
      border-top: 1px solid #ffffff84;
      display: inline-grid;
      padding-left: 12px;
      grid-auto-flow: column;
      grid-template-rows: repeat(8, auto);
      .item {
        min-width: 100px;
        & > span {
          padding: 5px !important;
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }
  }
}
