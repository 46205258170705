.cardContent {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 100%;
  min-height: 100px;
  padding: 10px 15px;
  &:last-child {
    padding-bottom: 0;
  }
  .header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .mainIcon {
      margin-right: 8px;
      width: 20px;
      height: 20px;
      margin-left: -3px;
    }
    .title {
      font-weight: 600;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    .column {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    .iconInfo {
      width: 16px;
      opacity: 0.8;
      margin-right: 8px;
    }
    .label {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }
    .labelCaption {
      opacity: 0.8;
      font-size: 12px;
      font-weight: 600;
      align-self: flex-end;
      justify-self: flex-end;
    }
    .dateBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .status {
      font-size: 14px;
      font-weight: 600;
      margin: 5px 0 8px 23px;
      align-self: flex-end;
    }
  }
}

.alert {
  background: rgba(212, 10, 40, 0.7);
  border-bottom: 5px solid #e80c2c;
}

.warning {
  background: rgba(253, 170, 41, 0.8);
  border-bottom: 5px solid #ffab28;
}
