.editedRow {
  background-color: #93f1ff38;
  border-left: 2px solid #93f1ff;
  &:hover {
    background-color: #93f1ff52 !important;
  }
}

.unEditedRow {
  background-color: transparent;
}
