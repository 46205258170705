$main-color:#93f1ff;
$dark-back: #373737;
$input-back: #2f2f2f;

.wiot_cron_builder {

.cron_builder_bordering {
    border:none;
    border-top: none;
    text-align: center;
    padding: 0;
    background:transparent;
    margin: 20px 0;
    min-height: 300px;
}
.cron_builder_bordering input[type=number], .cron_builder_bordering input[type=text], .cron_builder_bordering select {
    width: 100px;
    margin-right: 10px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    outline: none;
    padding: 0px 5px;
    min-height: 28px;

}
.df {
    display: flex;
}
.cron-builder-bg {
    background-color: $input-back;
    color: $main-color;
    text-align: center;
    margin-bottom: 4px;
    padding: 8px 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    text-transform: uppercase;
}
.cron_builder_bordering select {
    background-color:$input-back;
    border:none;
    width: 75px;
    font-family: "Titillium Web",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    color:#FFF;
    cursor: pointer;
    padding: 6px 20px;
    border-radius: 2px;
    margin-top: 20px;
    border-bottom: 1px solid;
}
.cron_builder_bordering select option:hover {
    background-color: #086090;
}
.well-small input[type=number], .well input[type=number], .well-small input[type=text], .well input[type=text] {
    width: auto !important;
    background-color:$input-back !important;
    color:#FFF !important;

}

.cron_builder {
    border: none;
    padding: 5px;
    background-color: transparent;
    width: auto;
    max-width: unset;
}
.text_align_left {
    text-align: left;
}
.cron_builder .nav li {
    cursor: pointer;
    flex: 1 1 10px;
    text-align: center;
    width: 10px;
    display: flex;
    padding: 0px 1px;
}
.cron_builder .nav li a {
    color:$main-color;
    width: 100%;
    font-weight: 500;
    line-height: 1.25;
    white-space: nowrap;
    text-transform: uppercase;
    padding: 10px;
    display: inline-block;
    border-radius: 0px;

}
.cron_builder .nav-tabs .nav-link:focus, .cron_builder .nav-tabs .nav-link:hover {
    border-color: transparent transparent transparent;
    background-color: transparent;

}
.cron_builder .nav-tabs .nav-item.show .nav-link, .cron_builder .nav-tabs .nav-link.active {
    border-color: none;
    border-bottom: 2px solid $main-color;
    background-color:transparent;
}
.cron_builder {
    font-size: 14px;
}
.cron_builder .well {
    min-height: unset;
    padding: 8px 20px;
    margin-bottom: 5px;
    background-color: #3A3A3A;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: none;

}

.tab-pane .well-small:last-child {
    margin-bottom: 210px;
}
@media screen and (max-width:767px) {
    .cron_builder .nav li {
        cursor: pointer;
        flex: 0 0 65px;
        text-align: center;
    }
}

/* ---- boostrap ----- */
.nav.nav-tabs {
    list-style: none;
    display: flex;
    margin: 0 0;
    padding-left: 0;
}
.row {
    display: flex;
}
.col-sm-6 {
    flex: 0 0 50%;
}
.min_height_auto {
    min-height: auto !important;
}
body {
    font-family: "Titillium Web",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}



/**
 * Removing the checkbox and radio appearance
 */

 input[type=radio],
 input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
 }

 /**
  * Giving Checkbox & Radio not checked a new appearance
  */

  input[type=radio]{
    min-width: 15px;
    min-height: 15px;
    border: solid 1px #cccccc;
    margin-right: 15px;
    position: relative;
  }
  input[type=radio]:checked, input[type=checkbox]:checked{
    border: solid 1px #93f1ff;

  }

  input[type=checkbox] {
   min-width: 15px;
   min-height: 15px !important;
   border: solid 1px #cccccc;
   margin-right: 15px;
   top:5px;
   position: relative;
 }

 /**
  * Giving Checkbox & Radio checked state a new appearance
  */

  input[type=radio]:checked::before {
    content: "";
    width: 9px;
    height: 9px;
    background-color: #93f1ff;
    position: absolute;
    top: 2px;
    left: 2px;
  }

  input[type=checkbox]:checked::before {
   content: "";
   width: 13px;
   height: 13px;
   background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+");
   background-size: 11px;
   background-repeat: no-repeat;
   background-color: #93f1ff;
   background-position: 1px 1px;
   position: absolute;
   top: 0;
   left: 0;
   border-radius: 2px;
 }

 /**
  * Rounding Radio inputs new appearance
  */

 input[type=radio],
 input[type=radio]:checked::before{
   border-radius: 100%;
 }




 select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
}



select {
    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
  }

  select:focus {
    background-image:
      linear-gradient(45deg, #93f1ff 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, #93f1ff 50%);
    background-position:
      calc(100% - 15px) 1em,
      calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
    border-color: #93f1ff;
    outline: 0;
  }


  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}