.dialog {
  width: 85%;
  height: 100%;
  margin: auto;
  overflow: hidden;
}

.dialogContent {
  min-height: 200;
  width: auto;
  padding: 15px 20px;
  
}

.container {
  display: flex;
  margin-bottom: 20px;
}

.dialogContainerActionButton {
  display: flex;
  justify-content: flex-end;
  padding: 5px 20px;
  gap:20px;
}

.text {
  color: #bfbfbf;
}

.cardSending {
  height: 400px;
  padding: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardOkResponse {
  height: 400px;
  padding: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #bfbfbf;
}

.input {
  width: 100%;
	height: 100%;
  padding-left: 20px;
  background-color: #333;
  border-color: #333;
}

.cardInput {
	height: 33px;
  width: 240px;
  margin-bottom: 20px;
  background-color: #242424;
}

.cardInputTextArea {
	height: 100px;
	width: 531px;
  margin-bottom: 20px;
  background-color: #242424;
}

.formLabel {
  margin-bottom: 5px;
  margin-left: 1px;
  color: #ffffff9c;
}


.infoIcon {
  margin-right: 12px;
}

.hidden {
  visibility: hidden;
}

.checkbox {
	border-color: #2E2E2E;
	color: #2E2E2E;
}

.warningText {
	color: #BBBBBB;
	margin-left: 15px;
	align-self: flex-end;
	padding-bottom: 6px;
}

.warningIcon {
	margin-left: 20px;
}

.selectorButtonContainer {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
    
  }

.selectButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    border-radius: 50px;
    padding-left: 15px;
    padding-right: 17px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    margin-right: 10px;
    color: #90ffff;
    svg {
      margin-right: 10px;
      height: 16px;
      width: 16px;
      path {
        fill: #90ffff;
      }
    }
    .findtypos {
      margin-right: 5px;
    }
    &.inactive {
      opacity: 0.7;
      &:hover {
        opacity: 0.8;
      }
      color: #e4e4e4;
      svg {
        path {
          fill: #e4e4e4;
        }
      }
    }
  }



.filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 15px 12px 0px;
		padding-left: 20px;
		background-color: #2E2E2E;
  }


// extraer a clases de form
.formGroupRow {
  gap: 25px;
  justify-content: flex-start;
  align-items: flex-end;
  .formControl {
    .select {
      min-width: 235px;
    }
  }
}
.inputSearch {
  width: 100%;
  font-weight: 500;
  height: 33px;
  padding: 0;
  color: #fff;
  background-color: #2E2E2E;
  border-radius: 4px;
  padding: 0 10px;
}  



.clearIcon {
	cursor: pointer;
  margin-left: -30px;
  z-index: 99;
}
.filterRow {
  margin: 0px 0 25px 0;
  display: flex;
  align-items: flex-end;
}