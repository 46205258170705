.dialogContainer {
  padding: 15px;
}
.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
}
.icon {
  width: 38px;
  height: 38px;
}
