.tooltipLogicalUnit {
  transition: all opacity ease-in 0.2s;
  position: absolute;
  width: 320px;
  opacity: 0;
  z-index: -999;
  background: #424242e6;
  border-radius: 5px;
  padding: 15px 20px 10px 20px;
  box-shadow: 1px 6px 12px rgba(0, 0, 0, 0.3);
  .title {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: 600;
  }
  .iconType {
    margin-right: 10px;
    height: 23px;
  }
  .closeIcon {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
