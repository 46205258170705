$primary: #93f1ff;
$secondary: #93f1ff;

$correcto: #82f4d2;
$atencion: #fdaa29;
$alerta: #e80c2c;

$atencionRow: rgba(253, 170, 41, 0.15);
$alertaRow: rgba(232, 12, 44, 0.15);

:export {
  primary: $primary;
  secondary: $secondary;
  correcto: $correcto;
  atencion: $atencion;
  alerta: $alerta;
}



/*PARA INTEGRACION CON IOTHANDLER*/
$borders-gray: #EBEAEB;
$button-green: #2e7d32;
$button-green-hover: rgb(32, 87, 35);
$red: #f00;
$button-red: #ff4343;
$button-red-hover: rgb(178, 46, 46);
$black: #484848;
$disabled-gray: rgba(0, 0, 0, 0.38);
$white: #fff;
$dark-blue: #1d558c;
$gray2: #d9d9d9;
$gray: #C1C3C6;
$blue: #387ac9;
$switch-base: #1d558c;
$light-gray: #f5f5f5;
/////////////////////////////